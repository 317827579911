import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { OrangeButton } from "@/lib/chakra/components";
import { useOnlineStatus } from "@/lib/customHooks";
import { inter } from "@/lib/fonts";
import { protectedPaths } from "@/lib/maps";
import { setFavourite } from "@/lib/reducers/apiData";
import { useTokenRefreshQuery } from "@/lib/services/authApi";
import { Flex, Text } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import LogRocket from "logrocket";
import { Router } from "next/router";

import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { setAccessToken, setAuthState } from "../../lib/reducers/user";
import { Loader } from "../utils/Loader";
import DataLoader from "./DataLoader";

interface Props {
  children: ReactNode;
  router: Router;
}

export const RootContext = createContext<{
  ctxTitle: string | JSX.Element;
  setCtxTitle: Dispatch<SetStateAction<string | JSX.Element>> | undefined;
}>({
  ctxTitle: "",
  setCtxTitle: undefined,
});

const AuthWrapper: React.FC<Props> = (props) => {
  const { children, router } = props;

  const [ctxTitle, setCtxTitle] = useState<string>("");
  const { user } = useAppSelector((s) => s.user);

  const online = useOnlineStatus();

  const { data, isLoading } = useTokenRefreshQuery();

  const dispatch = useAppDispatch();

  useEffect(() => {
    let fjson = null;
    try {
      fjson = JSON.parse(window.sessionStorage.getItem("hub2-favs"));
    } catch (e) {
      fjson = null;
    }
    if (!fjson) {
      fjson = {};
    }

    //Moved from below, make optional
    try {
      const client = localStorage.getItem("client");
      if (client) {
        fjson["client"] = client;
      }
    } catch (e) {}

    //For widget bypass in particular, override:
    const paramMap = { cid: "client", bid: "building", fpid: "floorplan" };
    const params = new URLSearchParams(window.location.search);
    for (const p in paramMap) {
      const pv = params.get(p);
      if (pv) {
        fjson[paramMap[p]] = pv;
      }
    }
    //****
    Object.keys(fjson).forEach((k) => {
      dispatch(setFavourite({ type: k, value: fjson[k] }));
    });
  }, []);

  useEffect(() => {
    const logrocketEnabled = process.env.NEXT_PUBLIC_LOGS;
    if (!isEmpty(user) && logrocketEnabled) {
      if (+user.logRocket! > 0) {
        LogRocket.init("gp2g0z/testapp");
        LogRocket.identify("gp2g0z/testapp", {
          name: user.email as string,
          email: user.email as string,
        });
      }
    }
  }, [user]);

  useEffect(() => {
    dispatch(setAuthState({ authState: "IDLE" }));
    if (data) {
      const { accessToken, email } = data;

      if (accessToken !== "") {
        dispatch(
          setAccessToken({
            accessToken,
          })
        );
      }

      const routeArray = router.asPath.split("/");
      const mainRoute = routeArray[1];

      if (accessToken === "" && protectedPaths.includes(mainRoute)) {
        router.push("/login");
      } else if (
        accessToken !== "" &&
        (router.asPath === "/login" || router.asPath === "/")
      ) {
        router.push("/dashboard");
      }
    }
  }, [data, dispatch, router]);

  if (!online) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        h="100vh"
        direction="column"
        gap={5}
      >
        <Text fontWeight={500} fontSize={"3xl"}>
          You are offline!
        </Text>
        <OrangeButton size="lg" onClick={() => location.reload()}>
          Try again
        </OrangeButton>
      </Flex>
    );
  }

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" h="100vh">
        <Loader />
        <noscript>
          <Text fontWeight={500} fontSize={"3xl"}>
            Please enable JavaScript, or update your browser to use this app.
          </Text>
        </noscript>
      </Flex>
    );
  }
  return (
    <RootContext.Provider value={{ ctxTitle, setCtxTitle }}>
      <div className={`${inter.className} antialiased`}>
        <DataLoader router={router} />
        {children}
      </div>
    </RootContext.Provider>
  );
};

export default AuthWrapper;
