import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import {
  IDeviceDetails,
  ILayoutItem,
  InRoomDevice,
  IRoom,
} from "../interfaces";

export interface AppSliceState {
  widgetsDate: Date | undefined;
  cafmDate: null | Date;
  cafmCalendarAction: string;
  cafmCalendarShift: number | undefined;
  zoom: number;
  isDraggable: boolean;
  layout: Array<ILayoutItem>;
  draggableWidget: string;
  layoutName: string;
  currentContract: string;
  currentBuilding: string;
  currentFloor: string;
  errors: {
    layoutName: boolean;
    customer: boolean;
  };
  search: string;
  fetchedDevices: Array<any>;
  devicesModal: boolean;
  roomIdModal: string;
  rooms: Array<IRoom>;
  resizingWidget: {
    w: number;
    h: number;
  };
}

type AppType = {
  widgetsDate: Date | undefined;
  resizingWidget: {
    w: number;
    h: number;
  };
  cafmDate: null | Date;
  cafmCalendarAction: string;
  cafmCalendarShift: number | undefined;
  zoom: number;
  isDraggable: boolean;
  layout: Array<ILayoutItem>;
  layoutName: string;
  draggableWidget: string;
  currentContract: string;
  currentBuilding: string;
  currentFloor: string;
  errors: {
    layoutName: boolean;
    customer: boolean;
  };
  search: string;
  fetchedDevices: Array<any>;
  devicesModal: boolean;
  roomIdModal: string;
  rooms: Array<IRoom>;
};

const initialState: AppType = {
  widgetsDate: undefined,
  resizingWidget: {
    w: 0,
    h: 0,
  },
  // itemResising: null,
  cafmDate: null,
  cafmCalendarAction: "",
  cafmCalendarShift: undefined,
  zoom: 100,
  isDraggable: true,
  search: "",
  errors: {
    layoutName: false,
    customer: false,
  },
  currentContract: "",
  currentBuilding: "",
  currentFloor: "",
  layoutName: "",
  layout: [],
  draggableWidget: "",
  fetchedDevices: [],
  devicesModal: false,
  roomIdModal: "",
  rooms: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    dashboardLogout: () => initialState,
    clearLayout(state: AppSliceState) {
      state.layout = [];
    },

    updateLayoutWidget(
      state: AppSliceState,
      action: PayloadAction<{
        widget: ILayoutItem;
        newSettings: Array<{ [key: string]: string | number | [] }>;
      }>
    ) {
      const { widget, newSettings } = action.payload;

      const currentWidget = state.layout.find(
        (item) => item.i === widget.i
      ) as ILayoutItem;
      if (currentWidget) {
        currentWidget.extraWidgetProps = {
          ...currentWidget?.extraWidgetProps,
          ...newSettings,
        };
      }
    },
    setWidgetsDate(
      state: AppSliceState,
      action: PayloadAction<{ date: Date }>
    ) {
      state.widgetsDate = action.payload.date;
    },
    setResizingWidget(
      state: AppSliceState,
      action: PayloadAction<{ w: number; h: number }>
    ) {
      try {
        state.resizingWidget = {
          w: action.payload.w,
          h: action.payload.h,
        };
      } catch (e) {}
    },
    addRoom(state: AppSliceState, action: PayloadAction<{ room: IRoom }>) {
      const { room } = action.payload;
      state.rooms.push(room);
    },
    setCafmCalendarAction(
      state: AppSliceState,
      action: PayloadAction<{ action: string }>
    ) {
      state.cafmCalendarAction = action.payload.action;
    },
    setCafmCalendarShift(
      state: AppSliceState,
      action: PayloadAction<{ shift: number }>
    ) {
      state.cafmCalendarShift = action.payload.shift;
    },
    setCafmDate(
      state: AppSliceState,
      action: PayloadAction<{ date: null | Date }>
    ) {
      state.cafmDate = action.payload.date;
    },
    setRoomIdModal(
      state: AppSliceState,
      action: PayloadAction<{ roomId: string }>
    ) {
      state.roomIdModal = action.payload.roomId;
    },
    setFetchedDevices(
      state: AppSliceState,
      action: PayloadAction<{ devices: Array<any> }>
    ) {
      state.fetchedDevices = action.payload.devices;
    },
    showDevicesModal(state: AppSliceState) {
      state.devicesModal = true;
    },
    hideDevicesModal(state: AppSliceState) {
      state.devicesModal = false;
    },
    setLayoutValue(
      state: AppSliceState,
      action: PayloadAction<{ layout: Array<ILayoutItem> }>
    ) {
      state.layout = action.payload.layout;
    },
    setDraggable(
      state: AppSliceState,
      action: PayloadAction<{ state: boolean }>
    ) {
      state.isDraggable = action.payload.state;
    },
    setLayout(
      state: AppSliceState,
      action: PayloadAction<{ layout: Array<ILayoutItem> }>
    ) {
      action.payload.layout.map((payload: ILayoutItem) => {
        const { x, y, h, w, i } = payload;
        state.layout.map((item: ILayoutItem) => {
          if (item.i === i) {
            item.x = x;
            item.y = y;
            item.w = w;
            item.h = h;
          }
        });
      });
    },
    addLayoutItem(
      state: AppSliceState,
      action: PayloadAction<{ item: ILayoutItem }>
    ) {
      state.layout.push(action.payload.item);
    },
    setDeviceCoords(
      state: AppSliceState,
      action: PayloadAction<{
        i: string;
        deviceId: string;
        x: number;
        y: number;
      }>
    ) {
      const { i, deviceId, x, y } = action.payload;
      const component = state.layout.find((item) => item.i === i);
      if (component) {
        component.devices?.map((item) => {
          if (item.deviceId === deviceId) {
            item.coords.x = x;
            item.coords.y = y;
          }
        });
      }
    },
    toggleDevice(
      state: AppSliceState,
      action: PayloadAction<{
        i: string;
        device: InRoomDevice;
        toggle: boolean;
      }>
    ) {
      const { i, device, toggle } = action.payload;
      const component = state.layout.find(
        (item) => item.i === i
      ) as ILayoutItem;
      if (component) {
        if (toggle) {
          //add
          component.devices?.push(device);
        } else {
          //remove
          const newDevices: Array<InRoomDevice> = [];
          component.devices?.map((item) => {
            if (
              item.roomId === device.roomId &&
              item.deviceId === device.deviceId
            ) {
              //any extra action on remove?
            } else {
              newDevices.push(item);
            }
          });

          if (component.devices) {
            if (component.devices.length) {
              component.devices.length = 0;
            }
          }

          newDevices.map((n) => {
            component.devices?.push(n);
          });
        }
      }
    },
    setLayoutErrors(
      state: AppSliceState,
      action: PayloadAction<{
        layoutName?: boolean;
        customer?: boolean;
      }>
    ) {
      state.errors.layoutName = action.payload.layoutName as boolean;
      state.errors.customer = action.payload.customer as boolean;
    },
    setSearch(state: AppSliceState, action: PayloadAction<{ search: string }>) {
      state.search = action.payload.search;
    },
    setDraggableWidget(
      state: AppSliceState,
      action: PayloadAction<{ widgetName: string }>
    ) {
      state.draggableWidget = action.payload.widgetName;
    },
    setLayoutName(
      state: AppSliceState,
      action: PayloadAction<{ name: string }>
    ) {
      state.layoutName = action.payload.name;
    },

    setCurrentContract(
      state: AppSliceState,
      action: PayloadAction<{ currentContract: string }>
    ) {
      state.currentContract = action.payload.currentContract;
    },
    setCurrentBuilding(
      state: AppSliceState,
      action: PayloadAction<{ currentBuilding: string }>
    ) {
      state.currentBuilding = action.payload.currentBuilding;
    },
    setCurrentFloor(
      state: AppSliceState,
      action: PayloadAction<{ currentFloor: string }>
    ) {
      state.currentFloor = action.payload.currentFloor;
    },

    removeLayoutItem(
      state: AppSliceState,
      action: PayloadAction<{ item: ILayoutItem }>
    ) {
      const newLayout: Array<ILayoutItem> = [];
      state.layout.map((item) => {
        if (item.i !== action.payload.item.i) {
          newLayout.push(item);
        }
      });

      state.layout = newLayout;
    },

    initZoom(state: AppSliceState, action: PayloadAction<{ zoom: number }>) {
      state.zoom = action.payload.zoom;
    },

    setZoom(state: AppSliceState, action: PayloadAction<{ zoom: number }>) {
      state.zoom = action.payload.zoom;
      localStorage.setItem("zoom", action.payload.zoom.toString());
    },
  },
});

export const {
  addLayoutItem,
  removeLayoutItem,
  setLayout,
  setSearch,
  setCurrentContract,
  setDraggableWidget,
  setCurrentBuilding,
  setLayoutValue,
  setLayoutName,
  clearLayout,
  setLayoutErrors,
  setCurrentFloor,
  dashboardLogout,
  setDraggable,
  toggleDevice,
  setDeviceCoords,
  setFetchedDevices,
  hideDevicesModal,
  showDevicesModal,
  setRoomIdModal,
  updateLayoutWidget,
  addRoom,
  initZoom,
  setZoom,
  setCafmCalendarAction,
  setCafmCalendarShift,
  setCafmDate,
  setResizingWidget,
  setWidgetsDate,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
